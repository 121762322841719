import { makeAutoObservable, runInAction } from 'mobx';
import { CreateAccount, SignIn } from '../api/firebase';
import { User } from '../interfaces/User.interface';

export default class UserStore {
	user: User | undefined = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	isLoggedIn = () => {
		return this.user !== undefined;
	};
	login = async (data: any) => {
		const user = await SignIn(data.email, data.password);
		runInAction(() => {
			const newUser = {
				// @ts-ignore
				username: user.displayName,
				// @ts-ignore
				id: user.uid,
			};
			this.user = newUser;
		});
		return;
	};
	logout = async () => {
		this.user = undefined;
	};
	register = async (data: any) => {
		const user = await CreateAccount(data.email, data.password, data.username);
		runInAction(() => {
			const newUser = {
				// @ts-ignore
				username: user.user.displayName,
				// @ts-ignore
				id: user.user.uid,
			};
			this.user = newUser;
		});
		return;
	};
	getUserId = (): string | null => {
		if (this.user) return this.user.id;
		else return null;
	};
	getUsername(): string | null {
		if (this.user) return this.user.username;
		else return null;
	}
}
