import { ErrorMessage, Field, useField } from 'formik';
import React from 'react';

export function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

interface Props {
	placeholder?: string;
	name: string;
	type: string;
	label?: string;
	labelTop?: boolean;
	as?: React.ElementType<any>;
	rows?: number;
	className?: string;
	autoComplete?: string;
}

export default function TextInput(props: Props) {
	const [field, meta] = useField(props.name);

	const data = { ...props };
	delete data.labelTop;
	return (
		<div className='d-flex row'>
			{props.label && (
				<label className={classNames(props.labelTop ? '' : 'col-2 mr-3 text-end')}>
					{props.label}
				</label>
			)}
			<div className='w-full col'>
				<Field
					as={props.as}
					placeholder={props.placeholder}
					{...field}
					{...data}
					className={classNames(
						props.className ? props.className : '',
						'form-control',
						meta.touched && meta.error ? 'border-1 border-danger' : ''
					)}
				/>
				<ErrorMessage component='div' name={props.name} className=' text-danger' />
			</div>
		</div>
	);
}
