import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useStore } from '../../../app/stores/Stores';

interface Props {
	post: any;
}

export default function RecentItem({ post }: Props) {
	const { articleStore } = useStore();

	return (
		<Link
			className='text-decoration-none recent-link'
			onClick={() => articleStore.setCurrentArticle(post.id!)}
			to={`/blog/${post.id}`}>
			<Card className='p-3 border-0 mb-3'>
				<Card.Img src={post.imgUrl} />
				<Card.Body className='pb-0'>
					<Card.Subtitle className='fs-6 text-muted mb-0'>{post.date}</Card.Subtitle>
					<Card.Title className='fs-5 text-black'>{post.title}</Card.Title>
				</Card.Body>
			</Card>
		</Link>
	);
}
