import { Col, Container, Row } from 'react-bootstrap';

export default function LoadingArticle() {
	return (
		<article>
			<Container className='mb-3'>
				<Row>
					<Col className='col-9'>
						<div className='ph-item ph-item-article'>
							<div className='ph-col-12 h-100'>
								<div className='ph-picture'></div>
								<div className='ph-row'>
									<div className='ph-col-6 big'></div>
									<div className='ph-col-4 empty big'></div>
									<div className='ph-col-4'></div>
									<div className='ph-col-8 empty'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-6 '></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-6'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-12'></div>
								</div>
							</div>
						</div>
					</Col>
					<Col className='col-3'>
						<div className='ph-item ph-item-article'>
							<div className='ph-col-12 h-100'>
								<div className='ph-row'>
									<div className='ph-col-12 big'></div>
									<div className='ph-col-12 big'></div>
									<div className='ph-col-12 big'></div>
									<div className='ph-col-12 big'></div>
									<div className='ph-col-12 big'></div>
									<div className='ph-col-12 big'></div>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</article>
	);
}
