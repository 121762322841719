import { observer } from 'mobx-react-lite';
import { useRef, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { Link, useParams } from 'react-router-dom';
import { Article } from '../../app/interfaces/Article.interface';
import { useStore } from '../../app/stores/Stores';
import LoadingArticle from './components/LoadingArticle';
import { ArrowBack } from '@mui/icons-material';
import Articlecomments from './components/ArticleComments';

const ArticlePage = () => {
	const { articleStore } = useStore();
	const articleRef = useRef(null);
	const [links, setLinks] = useState<string[]>();
	const { id } = useParams();
	const [currentArticle, setCurrentArticle] = useState<Article | undefined>(undefined);

	const getArticle = async () => {
		await articleStore.getArticleById(id);
		setCurrentArticle(articleStore.currentArticle);
	};

	useEffect(() => {
		if (!currentArticle) getArticle();

		if (articleRef && articleRef.current) {
			// @ts-ignore
			const children = articleRef.current.children;
			//@ts-ignore
			const links = [];
			for (let i = 0; i < children.length; i++) {
				if (children[i].localName === 'h3' || children[i].localName === 'h4') {
					children[i].id = children[i].innerText;
					links.push(children[i].innerText);
				}
			}
			setLinks(links);
		}
	}, [currentArticle]);

	if (!currentArticle) return <LoadingArticle />;

	return (
		<article>
			<Container className='mb-3'>
				<Link to={'/blog'} className='text-black text-decoration-none '>
					<ArrowBack />
					Back
				</Link>
				{currentArticle && (
					<Row>
						<Col ref={articleRef} className='col-12 col-lg-9 border-end border-2 '>
							<h1 className='pb-3'>{currentArticle.title}</h1>
							<img
								className='w-75 mb-3'
								src={currentArticle.imgUrl}
								alt={currentArticle.title}
							/>
							<ReactMarkdown>{currentArticle.text}</ReactMarkdown>
							<Articlecomments
								articleId={id!}
								comments={articleStore.articleComments}
							/>
						</Col>
						<Col className='d-none d-lg-block col-3'>
							<div className='position-sticky top-0'>
								<h4>Quick links</h4>
								<ul className='list-group'>
									{links &&
										links.map(link => (
											<li
												key={link}
												className='list-group-item list-group-item-secondary'>
												<a
													className='text-decoration-none text-black'
													href={'#' + link}>
													{link}
												</a>
											</li>
										))}
								</ul>
							</div>
						</Col>
					</Row>
				)}
			</Container>
		</article>
	);
};

export default observer(ArticlePage);
