import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function FooterSection() {
	return (
		<footer>
			<Container>
				<Row className='justify-content-center align-items-center main-row pt-5 pb-3'>
					<Col className='col-10 col-md-5 text-center'>
						<nav className='bottom-links d-inline'>
							<ul>
								<li className='d-inline d-md-block me-3 text-md-start'>
									<a className=' text-decoration-none' href='/#projects'>
										Projects
									</a>
								</li>
								<li className='d-inline d-md-block me-3 text-md-start text-decoration-none'>
									<a className=' text-decoration-none' href='/#service'>
										Services
									</a>
								</li>
								<li className='d-inline d-md-block me-3 text-md-start text-decoration-none'>
									<Link className=' text-decoration-none' to='/#contact'>
										Contact
									</Link>
								</li>
								<li className='d-inline d-md-block text-md-start text-decoration-none'>
									<Link className=' text-decoration-none' to='/blog'>
										Blog
									</Link>
								</li>
								<li className='d-inline d-md-block text-md-start text-decoration-none'>
									{/* FIXME */}
									<a
										className=' text-decoration-none'
										href='contents/cookiePolicy.html'>
										Cookie Policy
									</a>
								</li>
							</ul>
						</nav>
					</Col>
					<Col className='col-10 col-md-5'>
						<h3 className='text-center'>Follow me on:</h3>
						<nav className='mb-3 pt-3'>
							<Row className=' justify-content-center mx-auto'>
								<Col className='text-center'>
									<a href='https://twitter.com/johnlwebdev' target='_blank'>
										<TwitterIcon className='fs-1' />
									</a>
								</Col>
								<Col className='text-center'>
									<a
										href='https://www.instagram.com/johnlwebdev/'
										target='_blank'>
										<InstagramIcon className='fs-1' />
									</a>
								</Col>
								<Col className='text-center'>
									<a href='https://github.com/johnl6234' target='_blank'>
										<GitHubIcon className='fs-1' />
									</a>
								</Col>
							</Row>
						</nav>
					</Col>
					<Row className=' text-center align-items-end'>
						<Col className='col d-none d-md-block'></Col>
						<Col className='col-lg-5 mx-auto mb-3'>
							copyright &copy; John Lockham <span id='year'>2021</span>
						</Col>
						<Col className='text-sm-end'>
							<img
								className='badges'
								src='/html5-validator-badge-blue.png'
								alt='Valid HTML!'
							/>
							<img
								className='badges'
								src='http://www.w3.org/Icons/valid-css-blue'
								alt='Valid CSS!'
							/>
						</Col>
					</Row>
				</Row>
			</Container>
		</footer>
	);
}
