import React from 'react';
import { Button, Image } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useStore } from '../../../../app/stores/Stores';
import TextInput from './TextInput';

interface Props {
	onChangeForm: (name: string) => void;
	onHide: () => void;
}

export default function RegisterForm({ onChangeForm, onHide }: Props) {
	const { userStore } = useStore();
	const formSchema = Yup.object({
		username: Yup.string().required(),
		email: Yup.string()
			.required('Email is Password is mendatory')
			.email('Invalid email address format'),
		password: Yup.string()
			.required('Password is mendatory')
			.min(8, 'Password must be at 8 char long'),
		confirmPassword: Yup.string()
			.required('Password is mendatory')
			.oneOf([Yup.ref('password')], 'Passwords does not match'),
	});

	const handleFormSubmition = async (data: any) => {
		delete data.confirmPassword;
		await userStore.register(data);
		onHide();
	};

	return (
		<>
			<div className='w-100'>
				<h2 className='mx-auto text-center'>Lets get started</h2>
				<p className='mx-auto text-center'>Register for an account</p>
				<p className='text-center fs-6 fw-light'>
					Already have an Account? Login
					<Button
						className='ps-0 ms-1'
						variant='link'
						onClick={() => onChangeForm('login')}>
						here
					</Button>
				</p>
			</div>

			<Formik
				initialValues={InitialValues}
				enableReinitialize
				onSubmit={handleFormSubmition}
				validationSchema={formSchema}>
				{({ handleSubmit }) => (
					<Form autoComplete='new-password' onSubmit={handleSubmit}>
						<TextInput
							type='text'
							name='username'
							placeholder='Username'
							autoComplete='new-password'
							className='mb-2'
						/>
						<TextInput
							type='text'
							name='email'
							placeholder='Email'
							autoComplete='new-password'
							className='mb-2'
						/>
						<TextInput
							type='password'
							name='password'
							placeholder='Password'
							autoComplete='new-password'
							className='mb-2'
						/>
						<TextInput
							type='password'
							name='confirmPassword'
							placeholder='Confirm Password'
							autoComplete='new-password'
							className='mb-2'
						/>

						<Button variant='primary' type='submit' className='mt-1'>
							Register
						</Button>
					</Form>
				)}
			</Formik>
		</>
	);
}

const InitialValues = {
	displayName: '',
	username: '',
	email: '',
	password: '',
	confirmPassword: '',
};
