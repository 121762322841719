import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import ContactButton from '../../app/components/ContactButton';
import CustomNavbar from '../../app/components/CustomNavbar';
import MouseIcon from '../../app/components/MouseIcon';
import TypingHeader from '../../app/components/TypingHeader';

export default function HomeSection() {
	return (
		<section className='home-section' id='home'>
			<div className='overlay'>
				<Container className=' position-static content'>
					<CustomNavbar />
					<Row className='w-100 mx-auto mt-3 mt-lg-5 gx-5'>
						<Col className='px-5 col-12 col-lg-6'>
							<Image
								className='w-100 rounded-3 '
								src='./kevin-bhagat-zNRITe8NPqY-unsplash.jpg'
							/>
						</Col>
						<Col className='px-5 pt-3 col-12 col-lg-6'>
							<TypingHeader />
							<p className='my-4'>
								I am a web developer based in UK. I have over 5 years of experience,
								I enjoy building everything from small business sites to interactive
								web apps. If you are a business seeking a web presence, you can get
								in touch below
							</p>
							<ContactButton />
						</Col>
					</Row>
					<MouseIcon />
				</Container>
			</div>
		</section>
	);
}
