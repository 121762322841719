import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Navbar, Container, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useStore } from '../../app/stores/Stores';
import AuthModal from './components/auth/AuthModal';

const navigation = [
	{ name: 'Home', to: '/blog' },
	{ name: 'Johnlwebdeb', to: '/' },
];

const BlogNavbar = () => {
	const [expanded, setExpanded] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const { userStore } = useStore();

	const openAuth = () => {
		setModalShow(true);
	};
	return (
		<>
			<Navbar expand='lg' className='bg-light mb-3' expanded={expanded}>
				<Container>
					<Navbar.Toggle onClick={() => setExpanded(expanded ? false : true)} />
					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav className='ms-auto mt-auto'>
							{navigation.map(link => (
								<Link
									key={link.name}
									onClick={() => setExpanded(false)}
									to={link.to}
									className='nav-link'>
									{link.name}
								</Link>
							))}
							{userStore.isLoggedIn() ? (
								<Button
									onClick={() => userStore.logout()}
									className='nav-link login'
									variant='link'>
									Logout
								</Button>
							) : (
								<Button
									onClick={openAuth}
									className='nav-link login'
									variant='link'>
									Sign in
								</Button>
							)}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<AuthModal show={modalShow} onHide={() => setModalShow(false)} />
		</>
	);
};
export default observer(BlogNavbar);
