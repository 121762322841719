import { useEffect, useState } from 'react';
import { Comment } from '../../../app/interfaces/Comment.interface';
import { useStore } from '../../../app/stores/Stores';
import CommentForm from './CommentForm';
import CommentItem from './CommentItem';

import { observer } from 'mobx-react';

interface Props {
	articleId: string;
	comments?: Comment[];
}

const Articlecomments = ({ articleId, comments }: Props) => {
	const { articleStore, userStore } = useStore();
	const [backendComments, setBackendComments] = useState(comments);
	const [activeComment, setActiveComment] = useState(null);
	var rootComments: Comment[] = [];

	if (backendComments) {
		rootComments = backendComments.filter(
			backendComment => backendComment.parentId === null || backendComment.parentId === 'null'
		);
	}

	const getReplies = (commentId: string) =>
		backendComments!
			.filter(backendComment => backendComment.parentId === commentId)
			.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

	const addComment = (text: any, parentId?: null | undefined) => {
		articleStore.createComment(text, parentId, articleId).then(comment => {
			setBackendComments([comment, ...backendComments!]);
			setActiveComment(null);
		});
	};

	const updateComment = (text: any, commentId: any) => {
		//TODO
		console.log('edit comment');
	};

	const deleteComment = (commentId: any) => {
		if (window.confirm('Are you sure you want to remove comment?')) {
			// TODO
			console.log('Delete comment');
		}
	};

	useEffect(() => {
		setBackendComments(articleStore.articleComments);
	}, [articleStore.articleComments]);

	return (
		<div className='comments'>
			<h3 className='comments-title'>Comments</h3>
			<div className='comment-form-title'>Write comment</div>
			{userStore.isLoggedIn() ? (
				<CommentForm submitLabel='Write' handleSubmit={text => addComment(text, null)} />
			) : (
				<div className='bg-light p-3 rounded-3'>Log in to leave a comment</div>
			)}
			<div className='comments-container'>
				{rootComments.map(rootComment => {
					return (
						<CommentItem
							key={rootComment.id}
							comment={rootComment}
							replies={getReplies(rootComment.id!)}
							activeComment={activeComment}
							setActiveComment={setActiveComment}
							addComment={addComment}
							deleteComment={deleteComment}
							updateComment={updateComment}
							currentUserId={userStore.getUserId()}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default observer(Articlecomments);
