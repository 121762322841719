import { makeAutoObservable, runInAction } from 'mobx';
import {
	writeArticle,
	writeComment,
	getArticles,
	getComments,
	getArticleById,
} from '../../app/api/firebase';
import { Article } from '../interfaces/Article.interface';
import { Comment } from '../interfaces/Comment.interface';
import { store } from './Stores';

export default class ArticleStore {
	articles: Article[] | undefined = undefined;
	recent: Article[] | undefined = undefined;
	currentArticle: Article | undefined = undefined;
	articleComments: Comment[] | undefined;

	constructor() {
		makeAutoObservable(this);
	}

	getArticles = async () => {
		const data = await getArticles();
		runInAction(() => {
			// @ts-ignore
			this.articles = data;
			// @ts-ignore
			if (data.length > 3) this.recent = data.slice(0, 3);
			// @ts-ignore
			else this.recent = data;
		});
	};
	getArticleById = async (id: string | undefined) => {
		const data = await getArticleById(id!);
		this.getComments(id!);
		runInAction(() => {
			// @ts-ignore
			this.currentArticle = data;
		});
	};
	writeArticle = async (article: Article) => {
		writeArticle(article);
	};
	setCurrentArticle = (id: string) => {
		this.currentArticle = this.articles?.find(a => a.id === id);
		this.getComments(id!);
	};
	writeComment = (comment: Comment) => {
		//@ts-ignore
		writeComment(comment);
	};
	createComment = async (text: any, parentId = null, articleId: string) => {
		const comment: Comment = {
			articleId: articleId,
			body: text,
			parentId,
			userId: store.userStore.getUserId()!,
			username: store.userStore.getUsername()!,
			createdAt: new Date().toISOString(),
		};
		// @ts-ignore
		const commentId = await writeComment(comment);
		return { id: commentId, ...comment };
	};
	getComments = async (id: string) => {
		const data = await getComments(id);
		runInAction(() => {
			// @ts-ignore
			this.articleComments = data;
		});
		return data;
	};
}
