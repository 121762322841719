import { Card, Row, Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import {
	FacebookShareButton,
	FacebookIcon,
	TwitterShareButton,
	TwitterIcon,
	EmailShareButton,
	EmailIcon,
	PinterestShareButton,
	PinterestIcon,
} from 'react-share';
import { useStore } from '../../../app/stores/Stores';

interface Props {
	post: any;
}

export default function ArticleItem({ post }: Props) {
	const { articleStore } = useStore();

	const shareUrl = 'https://www.johnlwebdev.co.uk/blog/';

	return (
		<Card className='mb-3 p-3 border-0'>
			<Card.Img src={post.imgUrl} />
			<Card.Body className='pb-0'>
				<Card.Subtitle className='text-muted mb-3'>{post.date}</Card.Subtitle>
				<Card.Title>{post.title}</Card.Title>
				<Card.Text as='div'>
					<ReactMarkdown>{post.text}</ReactMarkdown>
				</Card.Text>
				<Row className='justify-content-between mt-3'>
					<Col>
						<Link
							onClick={() => articleStore.setCurrentArticle(post.id!)}
							className='btn btn-primary'
							to={`/blog/${post.id}`}>
							Read More...
						</Link>
					</Col>
					<Col>
						<div className='d-flex'>
							<ul className='ms-auto list-group list-group-horizontal'>
								<li className='list-group-item p-0'>
									<FacebookShareButton url={shareUrl + post.id}>
										<FacebookIcon />
									</FacebookShareButton>
								</li>
								<li className='list-group-item p-0'>
									<TwitterShareButton url={shareUrl + post.id}>
										<TwitterIcon />
									</TwitterShareButton>
								</li>
								<li className='list-group-item p-0'>
									<EmailShareButton url={shareUrl + post.id}>
										<EmailIcon />
									</EmailShareButton>
								</li>
								<li className='list-group-item p-0'>
									<PinterestShareButton
										url={shareUrl + post.id}
										media={post.imgUrl}>
										<PinterestIcon />
									</PinterestShareButton>
								</li>
							</ul>
						</div>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
}
