import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';

interface Props {
	show: boolean;
	onHide: () => void;
}

const AuthModal = ({ show, onHide }: Props) => {
	const changeComponent = (componentName: string) => {
		const component = components.find(c => c.name === componentName);
		setCurrentComponent(component!);
	};

	const components = [
		{
			name: 'login',
			component: <LoginForm onChangeForm={changeComponent} onHide={onHide} />,
		},
		{
			name: 'register',
			component: <RegisterForm onChangeForm={changeComponent} onHide={onHide} />,
		},
	];

	const [currentComponent, setCurrentComponent] = useState(components[0]);

	return (
		<Modal show={show} onHide={onHide} aria-labelledby='contained-modal-title-vcenter' centered>
			<Modal.Header closeButton className='border-0'></Modal.Header>
			<Modal.Body>{currentComponent.component}</Modal.Body>
		</Modal>
	);
};

export default observer(AuthModal);
