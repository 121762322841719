import { useEffect, useState } from 'react';

export default function TypingHeader() {
	const textArray = ['John Lockham', 'Web Developer', 'React Developer', 'Android Developer'];
	const [typing, setTyping] = useState(false);
	const [textIndex, setTextIndex] = useState(0);
	const [typingText, setTypingText] = useState(textArray[textIndex]);
	const delay = 175;

	useEffect(() => {
		setTimeout(() => {
			if (typing) {
				if (typingText.length < textArray[textIndex].length) {
					// Add letter
					var currentLetter = typingText.length;
					var text = typingText + textArray[textIndex][currentLetter];
					setTypingText(text);
				} else {
					setTyping(false);
				}
			} else {
				if (typingText.length > 0) {
					// Remove letter
					setTypingText(typingText.slice(0, -1));
				} else {
					var index = textIndex + 1;
					if (index > textArray.length - 1) setTextIndex(0);
					else setTextIndex(index);
					setTyping(true);
				}
			}
		}, delay);
	});
	return <h1 className=' fw-bold fs-2'>I am {typingText}|</h1>;
}
