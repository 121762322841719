import { Col, Container, Row } from 'react-bootstrap';

export default function LoadingBlog() {
	return (
		<article>
			<Container className='mb-3'>
				<Row>
					<Col className='col-8'>
						<div className='ph-item '>
							<div className='ph-col-12 h-100'>
								<div className='ph-picture'></div>
								<div className='ph-picture mt-0'></div>
								<div className='ph-picture mt-0'></div>
								<div className='ph-row px-3 pt-2'>
									<div className='ph-col-2 mb-2'></div>
									<div className='ph-col-8 empty'></div>
									<div className='ph-col-6 big'></div>
									<div className='ph-col-6 empty'></div>
									<div className='ph-col-12 empty'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-12 empty'></div>
									<div className='ph-col-2 big'></div>
									<div className='ph-col-6 big empty'></div>
									<div className='ph-col-4 big '></div>
								</div>
							</div>
						</div>
					</Col>
					<Col className='col-4'>
						<div className='ph-item '>
							<div className='ph-col-12 h-100'>
								<div className='ph-picture'></div>
								<div className='ph-picture mt-0'></div>
								<div className='ph-row'>
									<div className='ph-col-6 big'></div>
									<div className='ph-col-4 empty big'></div>
									<div className='ph-col-4'></div>
									<div className='ph-col-8 empty'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-12'></div>
									<div className='ph-col-12'></div>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</article>
	);
}
