import { Col, Container, Row } from 'react-bootstrap';
import LanguageIcon from '@mui/icons-material/Language';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import WebIcon from '@mui/icons-material/Web';
import ServiceCard from '../../app/components/ServiceCard';

const services = [
	{
		icon: <LanguageIcon className='fs-1 icon' />,
		title: 'Web design',
		text: 'I can design you a fully responsive website using Photoshop.',
	},
	{
		icon: <WebIcon className='fs-1 icon' />,
		title: 'Web development',
		text: 'I will code your website using HTML5/CSS3 and styled using the latest Bootstrap to make a website that is useable on all devices.',
	},
	{
		icon: <PhotoCameraIcon className='fs-1 icon' />,
		title: 'Photography',
		text: 'Our Photography department can produce high quality images for your website.',
	},
];

export default function ServiceSection() {
	return (
		<section className='service-section' id='services'>
			<Container>
				<h2 className='text-center fw-bold mb-5'>Services</h2>
				<Row className='justify-content-center gx-5 gx-md-3 align-items-stretch'>
					{services.map(service => (
						<Col className='col-10 col-lg-3 m-3' key={service.title}>
							<ServiceCard service={service} />
						</Col>
					))}
				</Row>
			</Container>
		</section>
	);
}
