import { Comment } from '../../../app/interfaces/Comment.interface';
import { useStore } from '../../../app/stores/Stores';
import CommentForm from './CommentForm';

interface Props {
	comment: any;
	replies: any;
	setActiveComment: any;
	activeComment: any;
	updateComment: any;
	deleteComment: any;
	addComment: any;
	parentId?: string | null;
	currentUserId: string | null;
}

const CommentItem = ({
	comment,
	replies,
	setActiveComment,
	activeComment,
	updateComment,
	deleteComment,
	addComment,
	parentId = null,
	currentUserId,
}: Props) => {
	const {
		userStore: { isLoggedIn },
	} = useStore();
	const isEditing =
		activeComment && activeComment.id === comment.id && activeComment.type === 'editing';
	const isReplying =
		activeComment && activeComment.id === comment.id && activeComment.type === 'replying';
	const fiveMinutes = 300000;
	// @ts-ignore
	const timePassed = new Date() - new Date(comment.createdAt) > fiveMinutes;
	const canDelete = currentUserId === comment.userId && replies.length === 0 && !timePassed;
	const canReply = isLoggedIn();
	const canEdit = currentUserId === comment.userId && !timePassed;
	const replyId = parentId ? parentId : comment.id;
	const createdAt = new Date(comment.createdAt).toLocaleDateString();

	return (
		<div key={comment.id} className='comment bg-light p-2 rounded-2 mb-2'>
			<div className='comment-image-container'>
				<img className='w-100' src='/user.png' alt='avatar' />
			</div>
			<div className='comment-right-part'>
				<div className='comment-content'>
					<div className='comment-author'>{comment.username}</div>
					<div>{createdAt}</div>
				</div>
				{!isEditing && <div className='comment-text'>{comment.body}</div>}
				{isEditing && (
					<CommentForm
						submitLabel='Update'
						hasCancelButton={true}
						initialText={comment.body}
						handleSubmit={text => updateComment(text, comment.id)}
						handleCancel={() => {
							setActiveComment(null);
						}}
					/>
				)}
				<div className='comment-actions'>
					{canReply && (
						<div
							className='comment-action'
							onClick={() => setActiveComment({ id: comment.id, type: 'replying' })}>
							Reply
						</div>
					)}
					{canEdit && (
						<div
							className='comment-action'
							onClick={() => setActiveComment({ id: comment.id, type: 'editing' })}>
							Edit
						</div>
					)}
					{canDelete && (
						<div className='comment-action' onClick={() => deleteComment(comment.id)}>
							Delete
						</div>
					)}
				</div>
				{isReplying && (
					<CommentForm
						submitLabel='Reply'
						handleSubmit={text => addComment(text, replyId)}
						hasCancelButton={true}
						handleCancel={() => {
							setActiveComment(null);
						}}
						initialText={''}
					/>
				)}
				{replies.length > 0 && (
					<div className='replies'>
						{replies.map((reply: Comment) => (
							<CommentItem
								comment={reply}
								key={reply.id!}
								setActiveComment={setActiveComment}
								activeComment={activeComment}
								updateComment={updateComment}
								deleteComment={deleteComment}
								addComment={addComment}
								parentId={comment.id!}
								replies={[]}
								currentUserId={currentUserId}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default CommentItem;
