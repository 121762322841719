import { Card } from 'react-bootstrap';

interface Props {
	service: any;
}

export default function ServiceCard({ service }: Props) {
	return (
		<Card className='h-100 p-3 shadow border-0 service-card'>
			<p className='text-center'>{service.icon}</p>
			<Card.Body className='text-center'>
				<Card.Title>{service.title}</Card.Title>
				<Card.Text>{service.text}</Card.Text>
			</Card.Body>
		</Card>
	);
}
