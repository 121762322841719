import { createContext, useContext } from 'react';
import ArticleStore from './ArticleStore';
import UserStore from './UserStore';

interface Store {
	articleStore: ArticleStore;
	userStore: UserStore;
}

export const store: Store = {
	articleStore: new ArticleStore(),
	userStore: new UserStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
	return useContext(StoreContext);
}
