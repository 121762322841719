import { Carousel, Col, Container, Row } from 'react-bootstrap';

const projects = [
	{
		title: 'JL Photography',
		description:
			'This is our photographers website, they have a fully equiped studio and can produce quality images at your request, create in simple HTML, Javascript and CSS',
		link: 'https://johnlphoto.github.io/portfolio/',
		imgUrl: './Jlphoto_mock.png',
	},
	{
		title: 'Marrietta Jewellers',
		description:
			'This is a mock online store, created using React, mobx and firebase, styled using Tailwind css',
		link: 'https://marriettajewels.web.app/',
		imgUrl: './jewellersMockup.jpg',
	},
	{
		title: 'Find Me A Coffee',
		description:
			'This is a website for finding your nearest coffee shop, created using MongoDb,Express,React and Node (MERN).',
		link: 'https://findmeacoffee.co.uk/',
		imgUrl: './fmac-mock.png',
	},
];

export default function ProjectSection() {
	return (
		<section className='project-section' id='projects'>
			<Container>
				<h2 className='text-center text-white fw-bold mb-lg-5 mb-3'>Projects</h2>
				<Carousel fade className='mx-auto' variant='dark' interval={3000}>
					{projects.map(project => (
						<Carousel.Item key={project.title}>
							<img
								className='d-block w-100'
								src={project.imgUrl}
								alt={project.title}
							/>
							<Carousel.Caption className='caption-bg rounded-3'>
								<h3>{project.title}</h3>
								<p>{project.description}</p>
								<a
									href={project.link}
									target='_blank'
									className='btn btn-outline-primary'>
									Live link
								</a>
							</Carousel.Caption>
						</Carousel.Item>
					))}
				</Carousel>
			</Container>
		</section>
	);
}
