import { Image } from 'react-bootstrap';

import { Transition } from 'react-transition-group';
import { useEffect, useRef, useState } from 'react';

const duration = 1000;

const defaultStyle = {
	transition: `opacity ${duration}ms ease-in-out`,
	opacity: 0,
};

const transitionStyles = {
	entering: { opacity: 1 },
	entered: { opacity: 1 },
	exiting: { opacity: 0 },
	exited: { opacity: 0 },
};

export default function MouseIcon() {
	const [inProp, setInProp] = useState(false);
	const nodeRef = useRef(null);
	useEffect(() => {
		setTimeout(() => {
			setInProp(state => !state);
		}, 1000);
	});
	return (
		<Transition
			nodeRef={nodeRef}
			in={inProp}
			timeout={duration}
			onExited={() => setInProp(state => !state)}>
			{state => (
				<div
					className='row justify-content-center mt-3 mt-lg-5'
					ref={nodeRef}
					style={{
						...defaultStyle,
						// @ts-ignore
						...transitionStyles[state],
					}}>
					<Image className='mouse-icon mt-5 mx-auto' src='./mouse_scroll.png' />
				</div>
			)}
		</Transition>
	);
}
