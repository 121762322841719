import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

export default function ReturnToTop() {
	const [visibility, setVisibility] = useState(false);
	const [offset, setOffset] = useState(0);

	useEffect(() => {
		const onScroll = () => setOffset(window.pageYOffset);
		// clean up code
		window.removeEventListener('scroll', onScroll);
		window.addEventListener('scroll', onScroll, { passive: true });
		return () => window.removeEventListener('scroll', onScroll);
	}, []);

	if (offset > 0 && !visibility) setVisibility(true);
	else if (offset === 0 && visibility) setVisibility(false);

	const goToTop = () => {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	};

	return (
		<Button
			hidden={!visibility}
			onClick={goToTop}
			variant='dark'
			className='to-top rounded-circle position-fixed bottom-0 end-0 me-3 mb-3 p-3 '>
			Top
		</Button>
	);
}
