// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import {
	addDoc,
	collection,
	doc,
	getDoc,
	getDocs,
	getFirestore,
	query,
	where,
} from 'firebase/firestore';
import {
	createUserWithEmailAndPassword,
	getAuth,
	signInWithEmailAndPassword,
	updateProfile,
} from 'firebase/auth';

import { Article } from '../interfaces/Article.interface';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyDQCYHX6bMaYGXN25yE3Btsx0wi4y5sHOU',
	authDomain: 'portfolio-34028.firebaseapp.com',
	databaseURL: 'https://portfolio-34028-default-rtdb.firebaseio.com',
	projectId: 'portfolio-34028',
	storageBucket: 'portfolio-34028.appspot.com',
	messagingSenderId: '969931900581',
	appId: '1:969931900581:web:e48dbd61232218df126f4c',
	measurementId: 'G-C253BVK8F8',
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);

export async function CreateAccount(email: string, password: string, username: string) {
	return new Promise(async (resolve, reject) => {
		try {
			const user = await createUserWithEmailAndPassword(auth, email, password);
			await updateProfile(auth.currentUser!, { displayName: username });
			resolve(user);
		} catch (err) {
			reject(err);
		}
	});
}

export async function SignIn(email: string, password: string) {
	return new Promise(async (resolve, reject) => {
		signInWithEmailAndPassword(auth, email, password)
			.then(userCredential => {
				// Signed in
				const user = userCredential.user;
				resolve(user);
			})
			.catch(error => {
				reject(error.message);
			});
	});
}

export async function writeArticle(article: Article) {
	try {
		const docRef = await addDoc(collection(db, 'articles'), {
			title: article.title,
			text: article.text,
			imgUrl: article.imgUrl,
		});
		console.log('Document written with ID: ', docRef.id);
	} catch (e) {
		console.error('Error adding document: ', e);
	}
}

export async function getArticleById(articleId: string) {
	return new Promise(async (resolve, reject) => {
		const docRef = doc(db, 'articles', articleId);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			resolve({ id: docSnap.id, ...docSnap.data() });
		} else {
			// doc.data() will be undefined in this case
			reject('No such document!');
		}
	});
}

export async function getArticles() {
	return new Promise(async (resolve, reject) => {
		const querySnapshot = await getDocs(collection(db, 'articles'));
		const dataArray: any[] = [];
		querySnapshot.forEach(doc => {
			// doc.data() is never undefined for query doc snapshots
			dataArray.push({ id: doc.id, ...doc.data() });
		});
		resolve(dataArray);
	});
}
export async function writeComment(comment: Comment) {
	console.log('set comment');
	try {
		const docRef = await addDoc(collection(db, '/comments'), comment);
		console.log('Document written with ID: ', docRef.id);
		return docRef.id;
	} catch (e) {
		console.error('Error adding document: ', e);
	}
}

export async function getComments(articleId: string) {
	return new Promise(async (resolve, reject) => {
		const comments = query(collection(db, 'comments'), where('articleId', '==', articleId));
		const querySnapshot = await getDocs(comments);
		const commentsArray: any = [];
		querySnapshot.forEach(doc => {
			commentsArray.push({ id: doc.id, ...doc.data() });
		});
		resolve(commentsArray);
	});
}
