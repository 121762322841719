import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useStore } from '../../../../app/stores/Stores';
import TextInput from './TextInput';

interface Props {
	onChangeForm: (name: string) => void;
	onHide: () => void;
}
export default observer(function LoginForm({ onChangeForm, onHide }: Props) {
	const { userStore } = useStore();
	const formSchema = Yup.object({
		email: Yup.string().required('Email is Password is mendatory').email(),
		password: Yup.string()
			.required('Password is mendatory')
			.min(3, 'Password must be at 3 char long'),
	});

	const onSubmit = async (data: any) => {
		await userStore.login(data);
		onHide();
	};

	return (
		<>
			<div className='w-100'>
				<h2 className='mx-auto text-center'>Welcome back</h2>
				<p className='mx-auto text-center'>Sign in to your account</p>
				<p className='text-center'>
					Dont have an Account? Register
					<Button
						className='ps-0 ms-1'
						variant='link'
						onClick={() => onChangeForm('register')}>
						here
					</Button>
				</p>
			</div>
			<Formik validationSchema={formSchema} onSubmit={onSubmit} initialValues={initialValues}>
				{({ handleSubmit }) => (
					<Form onSubmit={handleSubmit} className='w-75 mx-auto'>
						<TextInput name={'email'} type={'email'} className='mb-3' />
						<TextInput name={'password'} type={'password'} />

						<Button variant='primary' type='submit' className='mt-3'>
							Sign In
						</Button>
					</Form>
				)}
			</Formik>
		</>
	);
});

const initialValues = {
	email: '',
	password: '',
};
