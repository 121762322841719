import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';
import { Article } from '../../app/interfaces/Article.interface';
import { useStore } from '../../app/stores/Stores';
import LoadingBlog from './components/LoadingBlog';
import ArticleItem from './components/ArticleItem';
import RecentItem from './components/RecentItem';
const test = require('./testArticle.md');
const reactTest = require('./Resct.md');

const BlogHome = () => {
	const [articles, setArticles] = useState<Article[] | undefined>(undefined);
	const [recent, setRecent] = useState<Article[] | undefined>(undefined);
	const { articleStore } = useStore();

	useEffect(() => {
		if (!articles) getArticle();
	}, [articles]);

	const getArticle = async () => {
		await articleStore.getArticles();
		setArticles(articleStore.articles);
		setRecent(articleStore.recent);
	};

	const uploadFile = async () => {
		const article: Article = {
			id: uuidv4(),
			date: '11/07/2022', // TODO
			title: 'React Getting started',
			text: await fetch(reactTest).then(res => res.text()),
			imgUrl: 'https://res.cloudinary.com/johnlwebdev/image/upload/v1667815378/portolioBlog/react-1-logo-png-transparent_dfo7xn.png',
		};
		articleStore.writeArticle(article);
		console.log('done');
	};

	if (!articles) return <LoadingBlog />;

	return (
		<Container>
			{/* <Button onClick={uploadFile}>Test uplload</Button> */}
			<Row>
				<Col className='col-12 col-lg-8 px-2 px-lg-3'>
					{articles && articles.map(post => <ArticleItem key={post.id} post={post} />)}
				</Col>
				{/* recent posts */}
				<Col className='d-none d-lg-block col-4'>
					<div>
						{recent &&
							recent.map(post => <RecentItem key={post.id + 'recent'} post={post} />)}
					</div>
				</Col>
			</Row>
		</Container>
	);
};
export default observer(BlogHome);
