import { Formik, Form, Field } from 'formik';
import { Alert, Button, Container, Row } from 'react-bootstrap';
import { useForm } from '@formspree/react';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';

const initialValues = {
	fullName: '',
	email: '',
	message: '',
};

const formValidator = Yup.object({
	fullName: Yup.string().required(),
	email: Yup.string().email().required(),
	message: Yup.string().required(),
});

export default function ContactSection() {
	const [state, submitHandler] = useForm('mnqlwjgb');
	const [show, setShow] = useState(false);

	useEffect(() => {
		if (state.submitting) {
			setShow(true);
			setTimeout(() => {
				setShow(false);
			}, 2000);
		}
	}, [state]);

	return (
		<section className='contact-section' id='contact'>
			<Container>
				{show && (
					<Alert
						id='message-alert'
						className='mt-5 fs-4'
						variant={'success'}
						onClose={() => setShow(false)}
						dismissible>
						Message recieved, One of our team will get back to you as soon as possible.
					</Alert>
				)}
				<h2 className='text-center text-white fw-bold mb-5'>Contact Me</h2>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validationSchema={formValidator}
					onSubmit={submitHandler}>
					{({ handleSubmit, errors, touched }) => (
						<Form onSubmit={handleSubmit} className='col-10 col-md-6 mx-auto'>
							<div className='mb-3'>
								<Field
									className='form-control'
									name='fullName'
									placeholder='Full Name'
								/>
								{errors.fullName && touched.fullName ? (
									<div className='invalid-feedback d-block'>
										{errors.fullName}
									</div>
								) : null}
							</div>
							<div className='mb-3'>
								<Field className='form-control' name='email' placeholder='Email' />
								{errors.email && touched.email ? (
									<div className='invalid-feedback d-block'>{errors.email}</div>
								) : null}
							</div>
							<div className='mb-3'>
								<Field
									className='form-control'
									as='textarea'
									rows={4}
									name='message'
									placeholder='Message'
								/>
								{errors.message && touched.message ? (
									<div className='invalid-feedback d-block'>{errors.message}</div>
								) : null}
							</div>
							<Row className=' justify-content-center px-5'>
								<Button type='submit' disabled={state.submitting}>
									Submit
								</Button>
							</Row>
						</Form>
					)}
				</Formik>
			</Container>
		</section>
	);
}
