import { Formik, Form, Field } from 'formik';
import { Button } from 'react-bootstrap';
import * as yup from 'yup';

interface Props {
	handleSubmit: (text: any | undefined) => void;
	submitLabel: string;
	hasCancelButton?: boolean;
	handleCancel?: () => void;
	initialText?: string;
}
const CommentForm = ({
	handleSubmit,
	submitLabel,
	hasCancelButton = false,
	handleCancel,
	initialText = '',
}: Props) => {
	const onSubmit = (values: any, resetForm: any) => {
		handleSubmit(values.comment);
		resetForm({ values: '' });
	};

	const formSchema = yup.object({
		comment: yup.string().required(),
	});
	return (
		<Formik
			validationSchema={formSchema}
			enableReinitialize
			initialValues={{ comment: initialText }}
			onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}>
			{({ handleSubmit, touched, errors }) => (
				<Form onSubmit={handleSubmit}>
					<Field
						name='comment'
						className={
							touched.comment && errors.comment
								? 'border-1 border-danger form-control'
								: 'form-control mb-2'
						}
						as='textarea'
						rows={4}
						placeholder='Comment...'
					/>
					{touched.comment && errors.comment && (
						<div className='text-danger'>{errors.comment}</div>
					)}
					<Button className='comment-form-button mt-2' type='submit'>
						{submitLabel}
					</Button>
					{hasCancelButton && (
						<Button
							type='button'
							className='comment-form-button comment-form-cancel-button'
							onClick={handleCancel}>
							Cancel
						</Button>
					)}
				</Form>
			)}
		</Formik>
	);
};
export default CommentForm;
