import React from 'react';
import ContactSection from '../../features/sections/ContactSection';
import FooterSection from '../../features/sections/FooterSection';
import HomeSection from '../../features/sections/HomeSection';
import ProjectSection from '../../features/sections/ProjectSection';
import ServiceSection from '../../features/sections/ServiceSection';
import ReturnToTop from '../components/ReturnToTop';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import BlogHome from '../../features/Blog/BlogHome';
import ArticlePage from '../../features/Blog/ArticlePage';
import BlogNavbar from '../../features/Blog/BlogNavbar';

export function MainStack() {
	return (
		<main>
			<HomeSection />
			<ProjectSection />
			<ServiceSection />
			<ContactSection />
			<FooterSection />
			<ReturnToTop />
		</main>
	);
}
const addNavAndFoot = (element: JSX.Element) => {
	return (
		<>
			<BlogNavbar />
			{element}
			<FooterSection />
		</>
	);
};

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<MainStack />} />
				<Route path='/blog' element={addNavAndFoot(<BlogHome />)} />
				<Route path='/blog/:id' element={addNavAndFoot(<ArticlePage />)} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
