import { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const navigation = [
	{ name: 'Home', to: '#home' },
	{ name: 'Projects', to: '#projects' },
	{ name: 'Services', to: '#services' },
	{ name: 'Contact', to: '#contact' },
];

export default function CustomNavbar() {
	const [expanded, setExpanded] = useState(false);
	return (
		<Navbar expand='lg' className='mt-3 mt-lg-5' expanded={expanded}>
			<Container>
				<Navbar.Toggle onClick={() => setExpanded(expanded ? false : true)} />
				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='ms-auto'>
						{navigation.map(link => (
							<Nav.Link
								key={link.name}
								href={link.to}
								onClick={() => setExpanded(false)}>
								{link.name}
							</Nav.Link>
						))}
						<Link to='/blog' className='nav-link'>
							Blog
						</Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}
