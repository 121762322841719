import { useEffect, useState } from 'react';

export default function ContactButton() {
	const [shimmering, setShimmering] = useState(false);
	// Add shimmer effect to button hover
	const shimmer = () => {
		if (!shimmering) {
			setShimmering(true);
			let button = document.getElementById('contact-button');
			if (button) {
				let grad1 = 0;
				let grad2 = 0;
				let grad3 = 0;
				let i = 0;
				var check = function () {
					if (grad1 >= 120) {
						setShimmering(false);
					} else {
						setTimeout(_ => {
							grad1 = i;
							// if grad3 is at 20% start moving grad2
							if (i > 10) {
								grad2 = i - 10;
							}
							// if grad2 is at 20% start moving grad1
							if (i > 20) {
								grad3 = i - 20;
							}
							button!.style.background = `linear-gradient(70deg, rgba(0,63,89,1) ${grad3}%, rgba(0,142,255,1) ${grad2}%, rgba(0,63,89,1) ${grad1}%)`;
							i++;
							check();
						}, 1);
					}
				};
				check();
			}
		}
	};

	return (
		<a href='#contact' className='btn' id='contact-button' onMouseEnter={shimmer}>
			Contact
		</a>
	);
}
